export const autofitColumns = (json: any[]): Array<{ width: number }> => {

	const objectMaxLength: number[] = []
	
	json.map(jsonData => {
		Object.entries(jsonData)
			.map(([, v], idx) => {
				const columnValue = `${v}` as string
				objectMaxLength[idx] =
                    objectMaxLength[idx] >= columnValue.length
                    	? objectMaxLength[idx]
                    	: columnValue.length
			})
	})
    
	const wscols = objectMaxLength.map((w: number) => ({ width: w + 6 /* hard code plus 6 width */ }))
	// worksheet["!cols"] = wscols;
	return wscols
}