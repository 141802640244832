
import { reconcileShopOrder } from '@/api/reconcile/reconcile.api'
import { defineComponent, onMounted, reactive } from 'vue'
import { useRoute } from 'vue-router'
import dayjs, { formatDefault } from '@/utils/dayjs'
import xlsx from 'xlsx'
import { autofitColumns } from '@/utils/xlsx'
import { ReconcileParams } from '@/api/reconcile/reconcile.type'
import { linkWithTitle } from '@/use/useBreadcrumb'
import { useCourierStore } from '@/pinia/courier/courier.pinia'

const courierStore = useCourierStore()

export default defineComponent({
	name: 'ReconcileShopById',
	setup () {
		const route = useRoute()
		const state = reactive({
			isFetching: false,
			orderList: [] as any,
			pagination: {
				offset: 1,
				limit: 10,
				totals: 0
			},
			isExportingExcel: false,
		})
		const breadcrumbs = linkWithTitle()
		const fetchReconcileShop = () => {
			state.isFetching = true
			reconcileShopOrder({
				limit: state.pagination.limit,
				offset: state.pagination.offset,
				reconcileId: route.params.reconcileId as string,
				shopId: route.params.shopId as string,
			})
				.then(({ data: { data, pagination } }: any) => {
					state.orderList = courierStore.mapCourierWithOrder({data}).data
					state.orderList = state.orderList.map((each: any) => ({
						...each,
						pickupTime: formatDefault(each.pickupTime),
						destinationTime: formatDefault(each.destinationTime)
					}))
					
					state.pagination = {
						...state.pagination,
						totals: pagination.totals / state.pagination.limit
					}
				})
				.finally(() => state.isFetching = false)
		}

		const exportReconcile = async () => {
			state.isExportingExcel = true
			const shopId = route.params.shopId as string
			const reconcileId = route.params.reconcileId as string
			try {
				const params: ReconcileParams = {
					shopId,
					reconcileId,
					limit: 99999999 /* Hack Limit */,
					offset: 1,
				}
				const { data: { data: reconcileData }} = await reconcileShopOrder(params)
				// generate Excel
				const wsData = []
				const header = ['No.', 'Order Reference.', 'Courier', 'Tracking number', 'Recipient Name', 'Picked up Date', 'Destination Date', 'COD Amount']

				wsData.push(header)

				reconcileData.forEach((data: any, i: number) => {
					wsData.push([
						i + 1,
						data.reference || '-',
						data.courier?.name || '-',
						data.trackingNumber || '-',
						data.recipientName || '-',
						formatDefault(data.pickupTime),
						formatDefault(data.destinationTime),
						data.cost ? data.cost.toLocaleString(undefined, { minimumFractionDigits: 2 }) : '-'
					])
				})

				const worksheet = xlsx.utils.aoa_to_sheet(wsData)
				const workbook = xlsx.utils.book_new()

				// width maximize column with
				worksheet['!cols'] = autofitColumns(wsData)

				/* Add the worksheet to the workbook */
				xlsx.utils.book_append_sheet(workbook, worksheet, 'SheetJs')
				const fileName = `COD_Detail_${dayjs().format('DD-MM-YYYY')}-${reconcileId}.xlsx`
				// const fileName = `shopId-${shopId}-reconcileId-${reconcileId}.xlsx`
				xlsx.writeFile(workbook, fileName)

			} catch (error) {
				//
				console.error(error)
			} finally {
				state.isExportingExcel = false
			}
		}
		onMounted(() => {
			fetchReconcileShop()
		})
		return {
			state,
			fetchReconcileShop,
			exportReconcile,
			breadcrumbs,
		}
	}
})
