export interface LinkWithTitle {
    path: string;
    title: string;
}

export const linkWithTitle = (): LinkWithTitle[] => {
	const splitPath = window.location.pathname
		.split('/')
		.filter((path: string) => path)
		.reduce((prev, currentPath, i) => {
			return [
				...prev,
				{
					title: currentPath,
					path: prev[i - 1]
						? `${prev[i - 1]?.path}/${currentPath}`
						: `/${currentPath}`
				}
			]
		}, [] as any)
	

	return splitPath
}